<template>
  <app-overlay>
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">
        <!-- Brand logo-->
        <logo />

        <!-- Left Text-->
        <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img fluid :src="imgUrl" alt="Login V2" />
          </div>
        </b-col>
        <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <slot />
          </b-col>
        </b-col>
      </b-row>
    </div>
  </app-overlay>
</template>

<script>
import logo from "@core/layouts/components/Logo.vue";

export default {
  components: {
    logo,
  },
  props: {
    imgUrl: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
