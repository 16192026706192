<template>
  <auth-layout :img-url="imgUrl">
    <b-card-title title-tag="h2" class="font-weight-bold mb-1"> Şifrenizi mi unuttunuz? 🔒 </b-card-title>
    <b-card-text class="mb-2"> E-posta adresinize göndereceğimiz bağlantı veya telefonunuza göndereceğimiz 6 haneli kod ile kendinize yeni şifre oluşturabilirsiniz. </b-card-text>
    <hr />

    <validation-observer ref="forgotpassword">
      <b-tabs fill>
        <b-tab title="E-Posta">
          <app-input v-model="email" type="email" name="E-Posta" label="* E-Posta:" placeholder="E-Posta" rules="required|email" />
          <app-button text="Gönder" block @click="sendEmail" />
        </b-tab>
        <b-tab title="Telefon">
          <app-input v-model="email" type="email" name="* E-Posta" label="E-Posta:" placeholder="E-Posta" rules="required|email" />
          <app-input name="Telefon" type="phone" label="* Telefon:" placeholder="Telefon" />
          <app-button text="Gönder" block />
        </b-tab>
      </b-tabs>
    </validation-observer>
    <b-card-text class="text-center mt-2">
      <b-link :to="{ name: 'login' }"> <feather-icon icon="ChevronLeftIcon" /> Geri Dön </b-link>
    </b-card-text>
  </auth-layout>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver } from "vee-validate";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import AuthLayout from "../AuthLayout.vue";

export default {
  components: {
    ValidationObserver,
    AuthLayout,
    FeatherIcon,
  },
  data() {
    return {
      email: null,
    };
  },
  computed: {
    imgUrl() {
      const { skin } = this.$store.state.appConfig.layout;
      return skin === "dark" ? require("@/assets/images/pages/forgot-password-v2-dark.svg") : require("@/assets/images/pages/forgot-password-v2.svg");
    },
  },
  methods: {
    sendEmail() {
      this.$validate(this.$refs.forgotpassword, () => {
        this.$axios
          .post("/forgot-password", { email: this.email }, { loading: "table" })
          .then(() => {
            this.$router.push({ name: "login" });
            this.$emitter.$emit("Notification", {
              title: "İşlem Başarılı",
              variant: "success",
              message: "Şifre sıfırlama bağlantınız e-postanıza gönderilmiştir.",
            });
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", {
              title: "E-Posta adresi bulunamadı.",
              variant: "danger",
            });
          });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
